/**
 * Created by junzhang18 Wei on 2019/9/26.
 *封装正则regex
*/
export default {
  // 邮箱
  email: /(\w|-)+@((\w|-)+\.)+[a-z]{2,5}/,
  // 密码
  password: /^(?=.*?[a-zA-Z])(?=.*?\d)(?=.*?[!@#$%^&_+])[a-zA-Z\d!@#$%^&_+]{6,16}$/,
  // 手机号码
  telephone: /^1[3456789]\d{9}$/,
  // 验证码
  code: /^[0-9]{6}$/,
  // 前后空格
  aroundSpace: /(^\s*)|(\s*$)/g,
  // 中间空格替换成' '
  centerSpace: /\s+/g,
  // 数字格式化小数
  isAllZero: /[1-9]{1,}/,
  // 匹配正整数
  isNum: /^[1-9]\d*$/,
  // 匹配搜索框的字符
  searchSpace: /^([a-zA-Z0-9\u4E00-\u9FA5\s&:：（）()_-])/
}
